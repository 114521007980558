<template>
  <!-- 小助手 -->
    <div class="box-card" style="text-align: left">
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px" class="demo-ruleForm"
            size="small">
            <el-form-item label="标题：" prop="MenuName">
                <el-input v-model="addForm.MenuName" maxlength="15" placeholder="请输入标题"
                    @input="(e) => (addForm.MenuName = validSpacing(e))"></el-input>
            </el-form-item>
            <!-- <el-form-item label="菜单：">
          <el-select popper-class="my-select" v-model="addForm.ParentID" placeholder="请选择菜单">
            <el-option v-for="item in selectList" :key="item.id" :label="item.bypartitle" :value="item.id">
            </el-option>
          </el-select>

          <lbButton icon="peizhi2" style="margin-left: 15px" @click="
            isConfigMenuDialog = true;
          fnGetMaintenanceMain();
                      ">配置菜单</lbButton>
        </el-form-item> -->
            <el-form-item label="菜单：" prop="scene_id">
                <el-cascader popper-class="my-cascader" filterable v-model="addForm.ParentID" :options="treeData" clearable
                    :props="defaultParams" @change="handleChange" style="width:400px" placeholder="请选择菜单">
                    <template slot-scope="{ data }">
                        <span>{{ data.title }}</span>
                    </template>
                </el-cascader>
                <lbButton icon="peizhi2" style="margin-left: 15px" @click="isConfigMenuDialog = true;
                fnGetMaintenanceMain()">配置菜单</lbButton>
            </el-form-item>
            <el-form-item label="内容：" prop="Content">
                <div id="contentChildren"></div>
            </el-form-item>
        </el-form>
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click=" defaultForm ">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" class="checkButton" @click=" submitAddForm " v-preventReClick>保 存</lbButton>
        <img src="@/assets/img/alarm.png" alt="">
        <!-- 新增对话框 -->
        <lebo-dialog append-to-body title="配置菜单" :isShow=" isConfigMenuDialog " width="40%" @close=" isConfigMenuDialog=false "
            footerSlot>
            <el-tree :data=" treeData " :props=" defaultProps " node-key="id" default-expand-all :expand-on-click-node=" false "
                class="tree_box" highlight-current>
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span> {{ node.label }}</span>
                    <div class="icon_box">
                        <!-- @click.prevent.stop="openAddCategoryDialog(node, data)" -->
                        <i class="iconfont lebo-xinzeng" @click.prevent.stop=" openAddMenuDialog(node, data) "
                            v-if=" node.level < 3 "></i>
                        <i class="iconfont lebo-bianji" @click.prevent.stop=" openEditMenuDialog(node, data) "
                            v-if=" node.level != 1 "></i>
                        <i class="iconfont  lebo-shanchu1" @click.prevent.stop=" delMenu(node, data) "
                            v-if=" node.level != 1 "></i>
                    </div>
                </span>
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <!-- <lbButton type="goBack" icon="back" @click=" isConfigMenuDialog = false ">返 回</lbButton> -->
                <lbButton type="default" fill icon="confirm" @click=" isConfigMenuDialog = false ">保 存</lbButton>
            </span>
            <lebo-dialog width="30%" :title=" addMenuForm._id ? '修改类别' : '新增类别' " :isShow=" isAddMenuDialog " append-to-body
                @close=" closeAddMenuDialog " footerSlot>
                <el-form :model=" addMenuForm " :rules=" addMenuFormRules " ref="addMenuFormRef" label-width="120px"
                    class="demo-ruleForm" size="small">
                    <el-form-item label="上一级：" v-if=" addMenuForm.ParentID ">
                        {{ addMenuForm.ParentMenuName }}
                    </el-form-item>
                    <el-form-item label="菜单名称：" prop="MenuName">
                        <el-input v-model=" addMenuForm.MenuName " maxlength="10" placeholder="请输入菜单名称"
                            @input=" (e) => (addMenuForm.MenuName = validSpacing(e)) " style="width: 100%"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <!-- <lbButton type="goBack" icon="back" @click=" isAddMenuDialog = false ">返 回</lbButton> -->
                    <lbButton type="default" fill icon="confirm" @click=" fnAddMenuForm ">保 存</lbButton>
                </span>
            </lebo-dialog>
        </lebo-dialog>
    </div>
</template>

<script>
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import E from 'wangeditor'
import {
  getMaintenanceMain,
  updateMaintenanceMain,
  addMaintenanceMain,
  delMaintenanceMain,
  getSelectList,
  getMaintenanceMainDetail
} from '@/api/maintenanceCenter'
import request from '@/api/systemapi'
export default {
  props: ['id'],
  data () {
    return {
      // 富文本编辑器
      editor: null,
      addForm: {
        _id: '',
        MenuName: '',
        Type: 2,
        Content: '',
        ParentID: ''
      },
      addFormRules: {
        MenuName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        Content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      // 菜单下拉列
      selectList: [],
      // 是否展示菜单配置对话框
      isConfigMenuDialog: false,
      // 菜单树结构
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 是否展示新增/修改菜单结构
      isAddMenuDialog: false,
      addMenuForm: {
        _id: '',
        MenuName: '',
        Type: 2,
        ParentID: '',
        parentMenuName: ''
      },
      addMenuFormRules: {
        MenuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ]
      },
      defaultParams: {
        label: 'title',
        value: 'id',
        children: 'children'
      }
    }
  },
  created () { },
  mounted () {
    this.initEditor()
    this.fnGetSelectList()
    if (this.id) {
      this.addForm._id = this.id
      this.fnGetMaintenanceMainDetail(this.id)
    }
    this.isConfigMenuDialog = false
  },
  methods: {
    // 初始化富文本
    initEditor () {
      var that = this
      // console.log(document.getElementById('content'));
      this.editor = new E(document.getElementById('contentChildren'))
      this.editor.config.placeholder = '请输入内容...'
      // 配置菜单栏，设置不需要的菜单
      this.editor.config.excludeMenus = ['emoticon', 'video', 'todo']
      // 限制一次最多上传 1 张图片
      this.editor.uploadImgMaxLength = 1
      this.editor.config.showLinkImg = false
      this.editor.config.customUploadImg = function (files, insertImgFn) {
        that.uploadBase64Image(files, insertImgFn)
      }
      // 配置 onchange 回调函数
      this.editor.config.onchange = function (newHtml) {
        // console.log('change 之后最新的 html', newHtml)
      }
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 1000 // 修改为 500ms
      this.editor.config.zIndex = 500
      // 挂载highlight插件 （代码高亮）
      this.editor.highlight = hljs
      // 配置全屏功能按钮是否展示
      this.editor.config.showFullScreen = false
      this.editor.config.height = 450
      this.editor.create()
    },
    // 获取菜单下拉列表数据
    async fnGetSelectList () {
      const res = await getSelectList({
        type: 2
      })
      console.log(' this.selectList ', this.selectList)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        this.selectList = res.Data
      } else {
        this.selectList = []
      }
      console.log('菜单数据？', res)
      this.fnGetMaintenanceMain()
    },
    // 获取详情
    async fnGetMaintenanceMainDetail (id) {
      const res = await getMaintenanceMainDetail({
        id: id
      })
      console.log('获取详情----', res)
      if (res.Code === 200 && res.Data) {
        this.addForm.MenuName = res.Data.MenuName
        this.addForm.ParentID =
                    res.Data.ParentID === '000000000000000000000000'
                      ? ''
                      : res.Data.ParentID
        this.editor.txt.html(res.Data.Content)
      }
    },
    // 获取树控件数据
    async fnGetMaintenanceMain () {
      this.treeData = []
      const res = await getMaintenanceMain({
        type: 2
      })
      if (res.Code === 200) {
        if (res.Data && res.Data.length > 0) {
          // console.log(res)
          this.treeData.push({
            id: '1',
            title: '菜单',
            bypartitle: '',
            content: '',
            children: this.filterTreeData(res.Data, [])
          })
        } else {
          this.treeData.push({
            id: '1',
            title: '菜单',
            bypartitle: '',
            content: '',
            children: []
          })
        }
      } else {
        this.treeData = []
      }
      const rowList = this.filterChildren(this.treeData)
      this.treeData = rowList
      console.log('树形结构', this.treeData)
    },
    filterTreeData (data, arr) {
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.filterTreeData(item.children, [])
        }
        if (!item.content) {
          arr.push(item)
        }
      })
      return arr
    },
    // 上传base64图片
    uploadBase64Image (files, insertImgFn) {
      var that = this
      files.map((item) => {
        // 将图片文件转化成base64格式图片
        var reader = new FileReader()
        reader.onload = async (e) => {
          console.log('reader---', e)
          // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
          // 使用axios的post方法上传即可
          var base64image = e.target.result
          // // console.log("==-=-base64image=-=-", base64image);
          const res = await request.updataIcon({
            name: item.name,
            strbase64: [base64image]
          })
          var url = ''
          if (res.data && res.data.length > 0) {
            url = res.data[0].FilePath
            insertImgFn(url)
          } else {
            that.$msg.warning('你的图片格式有误请重新选择!')
          }
        }
        reader.readAsDataURL(item)
      })
    },
    // 打开新增菜单结构对话框
    openAddMenuDialog (node, data) {
      if (node.level !== 1) {
        this.addMenuForm.ParentID = data.id
        this.addMenuForm.ParentMenuName = data.title
      }
      this.isAddMenuDialog = true
      // console.log('openAddMenuDialog-----', node);
      // console.log('openAddMenuDialog-----', data);
    },
    // 打开编辑对话框
    openEditMenuDialog (node, data) {
      // if (node.level !== 1) {
      //   this.addMenuForm.ParentID = data.id
      //   this.addMenuForm.ParentMenuName = data.title
      // }
      this.addMenuForm._id = data.id
      this.addMenuForm.MenuName = data.title
      this.isAddMenuDialog = true
      console.log('openEditMenuDialog-----', node)
      console.log('openEditMenuDialog-----', data)
    },
    // 删除菜单
    delMenu (node, data) {
      // console.log('删除菜单----', node)
      // console.log('删除菜单----', data)
      this.$confirm('此操作将永久删除该菜单类别, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await delMaintenanceMain({
            id: data.id
          })
          if (res && res.Code === 200) {
            this.$msg.success(res.Message)
          }
          this.fnGetSelectList()
          this.fnGetMaintenanceMain()
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    // 新增或编辑菜单
    fnAddMenuForm () {
      this.$refs.addMenuFormRef.validate((valid) => {
        if (valid) {
          var obj = {
            MenuName: this.addMenuForm.MenuName,
            Type: this.addMenuForm.Type
          }
          if (this.addMenuForm.ParentID) {
            obj.ParentID = this.addMenuForm.ParentID
          }
          if (this.addMenuForm._id) {
            // 修改
            obj._id = this.addMenuForm._id
            this.fnUpdateMaintenanceMain(obj, 1)
          } else {
            // 新增
            this.fnAddMaintenanceMain(obj, 1)
          }
        } else {
          return false
        }
      })
    },
    // 新增请求
    async fnAddMaintenanceMain (obj, type) {
      console.log('新增请求', obj)
      // type  1-- 新增菜单；2-- 新增菜单内容
      const res = await addMaintenanceMain(obj)
      // console.log('新增请求----', res)
      if (res.Code === 200) {
        if (type === 1) {
          this.isAddMenuDialog = false
          this.fnGetMaintenanceMain()
        } else if (type === 2) {
          this.$emit('closeDialog', false)
        }
      }
    },
    // 关闭新增菜单对话框
    closeAddMenuDialog () {
      this.isAddMenuDialog = false
      this.addMenuForm._id = ''
      this.addMenuForm.MenuName = ''
      this.addMenuForm.ParentID = ''
      this.addMenuForm.parentMenuName = ''
    },
    // 修改提交
    async fnUpdateMaintenanceMain (obj, type) {
      console.log('提交修改', obj)
      const res = await updateMaintenanceMain(obj)
      if (res.Code === 200) {
        if (type === 1) {
          this.isAddMenuDialog = false
          this.fnGetMaintenanceMain()
        } else if (type === 2) {
          this.$emit('closeDialog', false)
        }
      }
    },
    // 返回上一页
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 新增数据
    submitAddForm () {
      this.addForm.Content = this.editor.txt.html()
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            MenuName: this.addForm.MenuName,
            Type: this.addForm.Type,
            Content: this.addForm.Content
          }
          if (this.addForm.ParentID) {
            obj.ParentID = this.addForm.ParentID
          }
          if (this.addForm._id) {
            // 修改
            obj._id = this.addForm._id
            this.fnUpdateMaintenanceMain(obj, 2)
          } else {
            // 新增
            this.fnAddMaintenanceMain(obj, 2)
          }
        } else {
          return false
        }
      })
    },
    // 选择菜单
    handleChange (value) {
      this.addForm.ParentID = value[[value.length - 1]]
      // this.addForm.category_id = value[[value.length - 1]]
      // this.addForm.scene_id = value[0]
      console.log(this.addForm.ParentID)
    }
  }
}
</script>

<style  scoped lang="less">
#contentChildren {
    -webkit-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
    outline: none;
    caret-color: #666 !important;
    // height: 500px;
}

.demo-ruleForm {

    .el-select,
    .el-date-editor,
    .el-input {
        width: 400px;
    }
}

.el-tree {
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
    overflow: auto;
    max-height: 600px;

    /deep/ .custom-tree-node {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            margin-right: 10px;
        }

        .icon_box {
            display: flex;
            text-align: left;

            .iconfont {
                display: block;
                margin-right: 5px;
                font-size: 18px !important;
            }
        }
    }
}

.box-card {
    position: relative;
    padding-bottom:0px;

    .checkButton {
        position: absolute;
        right: 0px;
        bottom: 0;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
        bottom: 0;
    }
}</style>
